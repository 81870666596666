import React, { useState } from 'react';

function AddEvent() {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [location, setLocation] = useState('');
    const [category, setCategory] = useState('');

    const handleLocationChange = (e) => {
        setLocation(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://www.project-tivity.com/api/events', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    EventTitle: title,
                    EventDate: date,
                    EventTime: time,
                    EventCategory: category,
                    EventLocation: location,
                    EventDesc: description
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to add event');
            }
            window.alert(`Event: ${title} added successfully!`);

            setTitle('');
            setDescription('');
            setDate('');
            setTime('');
            setLocation('');
            setCategory('');

            console.log('Event added successfully!');
        } catch (error) {
            console.error('Error adding event:', error.message);
        }
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '50px', fontFamily: 'Arial, sans-serif', fontSize: '16px' }}>
            <h2>Add New Event</h2>
            <form onSubmit={handleSubmit} style={{ width: '80%', margin: 'auto', border: '1px solid #ccc', borderRadius: '8px', padding: '20px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
                <div style={{ textAlign: 'left', marginBottom: '15px' }}>
                    <label htmlFor="title">Title:</label>
                    <input
                        type="text"
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', boxSizing: 'border-box' }}
                        placeholder="Enter event title"
                        required
                    />
                </div>

                <div style={{ textAlign: 'left', marginBottom: '15px' }}>
                    <label htmlFor="description">Description:</label>
                    <textarea
                        id="description"
                        rows={3}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        style={{ width: '100%', padding: '10px', fontFamily: 'Arial, sans-serif', borderRadius: '5px', border: '1px solid #ccc', boxSizing: 'border-box' }}
                        placeholder="Enter description"
                        required
                    />
                </div>

                <div style={{ textAlign: 'left', marginBottom: '15px' }}>
                    <label htmlFor="date">Date:</label>
                    <input
                        type="date"
                        id="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', boxSizing: 'border-box' }}
                        required
                    />
                </div>

                <div style={{ textAlign: 'left', marginBottom: '15px' }}>
                    <label htmlFor="time">Time:</label>
                    <input
                        type="time"
                        id="time"
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', boxSizing: 'border-box' }}
                        required
                    />
                </div>

                <div style={{ textAlign: 'left', marginBottom: '15px' }}>
                    <label htmlFor="location">Location:</label>
                    <select
                        id="location"
                        value={location}
                        onChange={handleLocationChange}
                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', boxSizing: 'border-box' }}
                        required
                    >
                        <option value="">Select location</option>
                        <option value="UBH">UBH</option>
                        <option value="Dunham Hall">Dunham Hall</option>
                        <option value="Stephens Hall">Stephens Hall</option>
                        <option value="John C Dunham Hall">John C Dunham Hall</option>
                        <option value="Institute Building">Institute Building</option>
                        <option value="Library">Library</option>
                    </select>
                </div>

                <div style={{ textAlign: 'left', marginBottom: '15px' }}>
                    <label htmlFor="category">Pillar:</label>
                    <select
                        id="category"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', boxSizing: 'border-box' }}
                        required
                    >
                        <option value="">Select pillar</option>
                        <option value="1">Health</option>
                        <option value="2">Social</option>
                        <option value="3">Academic</option>
                        <option value="4">Financial</option>
                    </select>
                </div>

                <button type="submit" style={{ backgroundColor: '#3636e4', color: 'white', fontSize: '1.2rem', width: '50%', padding: '10px' }}>
                    Add Event
                </button>
            </form>
        </div>
    );
}

export default AddEvent;