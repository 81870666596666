import React, { useEffect, useState } from 'react';

function AssessmentPage(props) {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await fetch('https://www.project-tivity.com/api/events');
                if (!response.ok) {
                    throw new Error('Failed to fetch events');
                }
                const eventData = await response.json();
                setEvents(eventData);
            } catch (error) {
                console.error('Error fetching events:', error.message);
            }
        };

        fetchEvents();
    }, []);

    const formatDateTime = (dateString, timeString) => {
        const date = new Date(dateString);
        const time = new Date(`2022-01-01T${timeString}`); // Dummy date for time

        // Format options for date
        const dateFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
        // Format options for time
        const timeFormatOptions = { hour: 'numeric', minute: '2-digit' };

        // Format date and time separately
        const formattedDate = date.toLocaleDateString('en-US', dateFormatOptions);
        const formattedTime = time.toLocaleTimeString('en-US', timeFormatOptions);

        // Combine formatted date and time
        return `${formattedDate} at ${formattedTime}`;
    };

    return (
        <div>
            <h2 style={{ color: 'white' }}>All Events</h2>
            <table style={{ width: '100%', borderCollapse: 'collapse', backgroundColor: 'white', color: 'black' }}>
                <thead>
                <tr>
                    <th>Title</th>
                    <th>Category</th>
                    <th>Description</th>
                    <th>Location</th>
                    <th>Time</th>
                </tr>
                </thead>
                <tbody>
                {events.map((event, index) => (
                    <tr key={index}>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{event.EventTitle}</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{event.EventCategory}</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{event.EventDesc}</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{event.EventLocation}</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{formatDateTime(event.EventDate, event.EventTime)}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default AssessmentPage;
