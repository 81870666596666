// Loading.js
import React from 'react';
import './loading.css'; // Import the styles
// import ReactLogo from './logo.svg'

function Loading(){
    return (
        <div className="App-header">
            <h1> Welcome Admin!</h1>
            <p> Please click the tabs above to navigate through the website</p>
        </div>
    );
}

export default Loading;