import React from 'react';
// import Navbar from "./components/navbar";

const ProjectTivity = () => {
    return (
        <h1 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>Project-tivity</h1>
    );
};

export default ProjectTivity;
