import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import './App.css';
import ProjectTivity from './projectivity';
import Loading from './loading';
import Navbar from "./components/navbar";
import AddEvent from "./components/addEvents";
import StatsPage from "./components/StatsPage";
import AllEvents from "./components/AllEvents";
import About from "./components/about";
import { GoogleLogin } from '@react-oauth/google';

function App() {
    const responseMessage = (response) => {
        console.log(response);
    };
    const errorMessage = (error) => {
        console.log(error);
    };
    return (
        <div>
            <Navbar />
            <Routes>
                <Route path="/" element={<>
                    <ProjectTivity />
                    <Loading />
                </>} />
                <Route path="/home" element={<Navigate to="/" />} />
                <Route path="/about" element={<About/>}/>
                <Route path="/AddEvent" element={<AddEvent/>} />
                <Route path="/Stats" element={<StatsPage/>} />
                <Route path="/AllEvents" element={<AllEvents/>} />
            </Routes>
            <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
        </div>
    );
}

export default App;