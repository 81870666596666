import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logoImage from '../imgs/P.png';

function BrandExample() {
    return (
        <Navbar expand="sm" style={{ backgroundColor: '#1d4fc5', padding: '10px 0' }}>
            <Container fluid style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Navbar.Brand href="/HomePage" style={{ padding: 0 }}>
                    <img src={logoImage} alt="Logo" style={{ width: '50px', height: '50px', objectFit: 'cover', paddingLeft: 10 }} />
                </Navbar.Brand>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <CustomNavLink to="/home">Home</CustomNavLink>
                        <CustomNavLink to="/about">About</CustomNavLink>
                        <CustomNavLink to="/AddEvent">Add Event</CustomNavLink>
                        <CustomNavLink to="/Stats">Statistics</CustomNavLink>
                        <CustomNavLink to="/AllEvents">All Events</CustomNavLink>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

const CustomNavLink = ({ to, children }) => {
    const location = useLocation();
    const isActive = location.pathname === to;

    return (
        <NavLink to={to} style={isActive ? { ...navLinkStyle, fontWeight: 'bold', color: 'white' } : navLinkStyle}>
            {children}
        </NavLink>
    );
};

const navLinkStyle = {
    color: '#78b9ff',
    marginLeft: '15px',
    fontSize: '16px',
    textDecoration: 'none',
    transition: 'color 0.3s ease',
};

export default BrandExample;

