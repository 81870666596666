import React from 'react';
import AdrianImage from '../imgs/adrianImage.jpg';
import './teamImages.css';
import LandoImage from '../imgs/lando.jpg';
import AlanImage from '../imgs/alan.jpg';
import ianImage from '../imgs/ian.jpeg';

function About() {
    return (
        <div>
            <h1>About the Project-tivity team</h1>

            <div className="container">
                <div className="team-member">
                    <img src={AlanImage} alt="Alan" className="image" />
                    <h2>Alan Martinez</h2>
                    <h3>Backend developer</h3>
                    <p>
                        Alan Martinez is a senior at Aurora University, majoring in Computer Science. He is seeking
                        a career in software engineering, likes video games, and is passionate about technology.
                    </p>
                </div>

                <div className="team-member">
                    <img src={AdrianImage} alt="Adrian" className="image" />
                    <h2>Adrian Gomez</h2>
                    <h3>Backend developer</h3>
                    <p>
                        Adrian Gomez is a senior at Aurora University, double majoring in Computer Science and Business
                        Administration. He is seeking a career in software development and is passionate about making
                        impactful, efficient software.
                    </p>
                </div>

                <div className="team-member">
                    <img src={ianImage} alt="Ian" className="image" />
                    <h2>Ian Bain</h2>
                    <h3>Front end developer</h3>
                    <p>
                        Ian is a Computer Science student at Aurora University. He enjoys hockey and fishing.
                    </p>
                </div>

                <div className="team-member">
                    <img src={LandoImage} alt="Orlando" className="image" />
                    <h2>Orlando Lopez</h2>
                    <h3>Front end developer</h3>
                    <p>
                        Orlando Lopez is a senior at Aurora University and is majoring in Computer Science.
                        He is seeking a career in IT and is passionate about technology and likes to fish.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default About;