import React, {useEffect, useState} from 'react';
import './stats.css';
import { PieChart, Pie, Cell } from 'recharts';

function StatsPage(props) {
    const [totalTasksCompleted, setTotalTasksCompleted] = useState(null);
    const [totalAccounts, setTotalAccounts] = useState(null);
    const [totalAccountsFirstAssessment, setTotalAccountsFirstAssessment] = useState(null);
    const [totalOverdueTasks, setTotalOverdueTasks] = useState(null);
    const [totalCompletedGoals, setTotalCompletedGoals] = useState(null);
    const [avgCompletedTasks, setAvgCompletedTasks] = useState(null);
    const [avgCompletedGoals, setAvgCompletedGoals] = useState(null);
    const[freshmans, setFreshmans] = useState(null);
    const[sophomores, setSophomores] = useState(null);
    const[juniors, setJuniors] = useState(null);
    const[seniors, setSeniors] = useState(null);

    const [showQuestions, setShowQuestions] = useState(false);

    const toggleQuestions = () => {
        setShowQuestions(!showQuestions);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const tasksCompletedResponse = await fetch('https://www.project-tivity.com/api/totalCompletedTasks');
            const accountsResponse = await fetch('https://www.project-tivity.com/api/totalAccounts');
            const accountsFirstAssessmentResponse = await fetch('https://www.project-tivity.com/api/totalAccountsFirstAssessment');
            const totalOverdueTasksResponse = await fetch('https://www.project-tivity.com/api/totalOverdueTasks');
            const totalCompletedGoalsResponse = await fetch('https://www.project-tivity.com/api/totalCompletedGoals');
            const avgCompletedTasksResponse = await fetch('https://www.project-tivity.com/api/AvgCompletedTasks');
            const avgCompletedGoalsResponse = await fetch ('https://www.project-tivity.com/api/AvgCompletedGoals');
            const useryearsResponse = await fetch('https://www.project-tivity.com/api/userYearCounts')

            if (!tasksCompletedResponse.ok || !accountsResponse.ok || !accountsFirstAssessmentResponse.ok ||
                !totalOverdueTasksResponse.ok || !totalCompletedGoalsResponse.ok || !avgCompletedTasksResponse.ok || !avgCompletedGoalsResponse) {
                throw new Error('Failed to fetch data');
            }

            const tasksCompletedData = await tasksCompletedResponse.json();
            const accountsData = await accountsResponse.json();
            const accountsFirstAssessmentData = await accountsFirstAssessmentResponse.json();
            const totalOverdueTasksData = await totalOverdueTasksResponse.json();
            const totalCompletedGoalsData = await totalCompletedGoalsResponse.json();
            const avgCompletedTasksData = await avgCompletedTasksResponse.json();
            const avgCompletedGoalsData = await avgCompletedGoalsResponse.json();
            const userYearData = await useryearsResponse.json();

            setTotalTasksCompleted(tasksCompletedData.totalCompletedTasks);
            setTotalAccounts(accountsData.totalAccounts);
            setTotalAccountsFirstAssessment(accountsFirstAssessmentData.totalAccounts);
            setTotalOverdueTasks(totalOverdueTasksData.totalOverdueTasks);
            setTotalCompletedGoals(totalCompletedGoalsData.totalCompletedGoals);
            setAvgCompletedTasks(avgCompletedTasksData.avgTasksCompleted);
            setAvgCompletedGoals(avgCompletedGoalsData.avgGoalsCompleted)
            setFreshmans(userYearData.freshman);
            setSophomores(userYearData.sophomore);
            setJuniors(userYearData.junior);
            setSeniors(userYearData.senior);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const data = [
        { name: 'Freshmen', students: freshmans, color: "red" },
        { name: 'Sophomores', students: sophomores, color: "blue" },
        { name: 'Juniors', students: juniors, color: "#FF8C00" },
        { name: 'Seniors', students: seniors, color: "green" }
    ];
    return (
        <div className="statsContainer">
            <simpleStats>
                <h1>Statistics Page</h1>
                <h4>Total accounts created: {totalAccounts}</h4>
                <h4>Total Accounts that must take first assessment: {totalAccountsFirstAssessment}</h4>
                <h4>Total Tasks/Goals that are overdue: {totalOverdueTasks}</h4>
                <h4>Total tasks completed by all students: {totalTasksCompleted}</h4>
                <h4>Total goals completed by all students: {totalCompletedGoals}</h4>
                <h4>Average number of tasks completed by each student: {avgCompletedTasks}</h4>
                <h4>Average number of goals completed by each student: {avgCompletedGoals}</h4>
                
                <div className={"assessment questions"}>
                    {showQuestions && (
                        <>
                            <h5>To what extent do you feel comfortable seeking help or assistance from professors or academic support services?:<span> / 5</span></h5>
                            <h5>Rate your ability to handle stress and pressure related to academic demands.: <span>{}/5</span></h5>
                            <h5>How confident are you in your ability to adapt to new and challenging academic situations?: <span>{}/5</span></h5>
                            <h5>How confident are you in your note-taking skills during lectures or while reading academic material?: <span>{}/5</span></h5>
                            <h5>Rate your proficiency in utilizing different study techniques, such as flashcards, mind maps, or summarization.: <span>{}/5</span></h5>
                            <h5>To what extent do you feel comfortable using technology to enhance your study habits, such as online resources, apps, or educational platforms?: <span>{}/5</span></h5>
                            <h5>How well do you think you can identify and focus on key concepts when reviewing course materials for exams?: <span>{}/5</span></h5>
                            <h5>Please rate your ability to stay focused and avoid distractions while studying.: <span>{}/5</span></h5>
                            <h5>How confident are you in your time management skills specifically related to study sessions and exam preparation?: <span>{}/5</span></h5>
                            <h5>How happy are you with your academic life at the moment?: <span>{}/5</span></h5>

                        </>
                    )}
                </div>
                <div className={"assessmentAverageHeader"} onClick={toggleQuestions}>Click to View Assessment Averages</div>
            </simpleStats>

            <div className="pieChartContainer">
                <PieChart width={500} height={300}>
                    <Pie
                        data={data}
                        dataKey="students"
                        outerRadius={100}
                        fill="#8884d8"
                        label={({ name }) => `${name} (${data.find(item => item.name === name).students})`}
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                    </Pie>
                </PieChart>
                <h4 style={{textAlign: 'center'}}>Users by Academic Year</h4>

            </div>

        </div>
    );
}

export default StatsPage;